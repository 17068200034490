<template>
  <!-- 挂号记录页面    -->
  <div class="jilu">
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooiseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-chooise-jzk-over">
        <div class="yyqr-chooise-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-chooise-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooiseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-chooise-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-chooise-add" @click="addPatient">
        <van-icon name="plus" />添加就诊人
      </div>
    </van-popup>
    <div class="jilu1" v-for="(item, index) in recordsList" :key="index">
      <div class="baogao2">
        <span style="padding-left: 10px; color: #a4a5ab">挂号日期：</span>
        <span style="padding-right: 10px">{{ item.time.split("_")[0] }}</span>
      </div>
      <div class="baogao2">
        <span style="padding-left: 10px; color: #a4a5ab">挂号科室：</span>
        <span style="padding-right: 10px">{{ item.dept_name }}</span>
      </div>
      <div class="baogao2">
        <span style="padding-left: 10px; color: #a4a5ab">挂号费：</span>
        <span style="padding-right: 10px; color: #ef8e29"
          >¥ {{ item.amount }}</span
        >
      </div>
      <div class="baogao2">
        <span style="padding-left: 10px; color: #a4a5ab">来源：</span>
        <span style="padding-right: 10px; letter-spacing: 2px">{{
          item.sorce
        }}</span>
      </div>
      <div class="baogao3">
        <div style="padding-left: 10px; color: #a4a5ab"></div>
        <van-button plain size="small" type="primary">电子票据</van-button>
        <!-- <van-button plain size="small" type="danger">退款</van-button> -->
        <div class="baogao4" @click="evaluate(item)">
          <img src="../../assets/img/ucenter/22.png" alt="" />
          <div>写评价</div>
        </div>
      </div>
    </div>
    <div v-if="recordsList.length == 0">
      <img src="../../assets/img/ucenter/21.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // name:"",
      showPopup: false,
      isEmpty: false,
      chooiseItem: "",
      recordsList: [],
      list: [],
    };
  },
  methods: {
    evaluate(item) {
      // 跳转写评价页面
      window.sessionStorage.setItem("recordsList", JSON.stringify(item));
      this.$router.push({
        path: "/evaluate",
        query: {
          id: this.chooiseItem.patId,
          name: this.chooiseItem.name,
        },
      });
    },
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    chooiseJZPerson(item) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      this.chooiseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      this.getRecords();
    },
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status == 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooiseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooiseItem = this.list[i];
                  }
                }
                if (!this.chooiseItem.deafult) {
                  this.chooiseItem.deafult = true;
                  this.list[0].deafult = true;
                }
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooiseItem = CurrentJZPerson;
            }
            this.getRecords();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getRecords() {
      // this.recordsList = [ { "item_name": "挂号费", "item_class": "Z", "amount": "15.5", "dept_code": "40080108", "dept_name": "肿瘤科付亚杰", "time": "2021-11-11_肿瘤科付亚杰_08:00:00_11:15:00", "doctor_name": "付亚杰", "doctor_code": "20158", "order_id": "GH2021110800095410" },{ "item_name": "挂号费", "item_class": "Z", "amount": "5.5", "dept_code": "40080114", "dept_name": "肿瘤科叶丰进", "time": "2021-11-09_肿瘤科叶丰进_08:00:00_17:15:00", "doctor_name": "叶丰进", "doctor_code": "20595", "order_id": "GH2021110900096099" } ]
      let postData = {
        healthId: this.chooiseItem.healthId,
        status: true,
      };
      this.$http
        .get("/api/wxappointment/paymentList", postData)
        .then((res) => {
          this.recordsList = res.data;
        })
        .catch((err) => {
          Toast({ message: err.response.data });
          console.log(err);
        });
    },
    dateFtt(fmt, date) {
      //author: meizz
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style scoped>
@import url(//at.alicdn.com/t/font_2783444_59ar2jwrggs.css);
.van-button {
  border-radius: 5px;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.body {
  background-color: #f8f9f9;
}
.jilu {
  width: 100%;
  height: 100%;
}
.jilu1 {
  width: 9rem;
  height: 200px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 16px;
}
.baogao2 {
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
}
.baogao3 {
  height: 40px;
  line-height: 40px;
  border-top: 1px solid #d4d4d4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.baogao4 {
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.baogao4 img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.yyqr-chooise-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}
.yyqr-chooise-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}
.yyqr-chooise-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}
.yyqr-chooise-jzk .checked {
  color: #03d28c;
}
.yyqr-chooise-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}
.yyqr-chooise-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-chooise-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}
.yyqr-chooise-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}
</style>
